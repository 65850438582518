<template>
  <QCard
    class="br-25 cursor-pointer bg-grey-1 bd-1 bd-grey-3"
    :class="{ 'bd-positive': isCVThequeUnlocked(applicant.id) }"
    :style="$route.name === 'offer' ? 'cursor:grab!important' : ''"
    @click="emitEvent((lockCvtheque && !isCVThequeUnlocked(applicant.id)) ? { action: 'askUnlock' } : undefined)"
  >
    <QCardSection class="q-pb-sm">
      <div class="flex items-center justify-between no-wrap full-width">
        <div class="flex items-center">
          <QSkeleton
            v-if="cptLoading"
            type="circle"
            width="35px"
            height="35px"
            class="br-25 gt-md"
          />
          <AppAvatar
            v-else
            class="gt-md"
            size="35px"
            :entity="applicant.profileAsset ?? applicant"
          />
          <div class="column justify-center items-start q-mx-sm">
            <QSkeleton
              v-if="cptLoading"
              type="text"
              height="2.3em"
              width="10em"
            />
            <div
              v-else
              class="text-weight-medium ellipsis"
              :style="isRestricted ? 'filter: blur(0.25rem)' : ''"
            >
              {{ $_.truncate(`${$_.get(applicant, 'firstname', '-')} ${$_.get(applicant, 'lastname', '-')}`) }}
            </div>
            <div
              v-if="jobrefLabel && $_.get(applicant, 'profileAsset.customAttributes.verifiedProfile', false)"
              class="flex items-center"
            >
              <div class="text-caption text-grey-5 q-mr-sm">
                {{ jobrefLabel }}
              </div>
              <QIcon
                v-if="$_.get(applicant, 'profileAsset.customAttributes.verifiedProfile', false)"
                name="uil:check-circle"
                color="positive"
                size="sm"
              >
                <ActionTooltip path="profile.profile_verified" />
              </QIcon>
            </div>
          </div>
        </div>
        <HCButton
          v-if="cvThequeUnlockTimestamp"
          color="positive"
          icon="uil:unlock"
          :tooltip="cvThequeUnlockTimestamp.since"
        />
        <slot v-else-if="!cvThequeUnlockTimestamp && lockCvtheque" name="checkbox-select" />
      </div>
    </QCardSection>
    <QSeparator class="q-my-xs q-px-md" />

    <QCardSection
      v-if="cptLoading"
      class="q-pt-none flex items-center q-gutter-sm"
    >
      <QSkeleton
        type="chip"
        dense
        width="3em"
      />
      <QSkeleton
        type="chip"
        dense
        width="5em"
      />
      <QSkeleton
        type="chip"
        dense
        width="8em"
      />
    </QCardSection>
    <QCardSection
      v-else-if="chips.length"
      class="q-pt-none"
    >
      <QChip
        v-for="(chip, i) of chips"
        :key="i"
        :color="chip.color ?? 'grey-2'"
        :icon="chip.icon"
        :label="chip.label"
        dense
        :clickable="typeof chip.fn === 'function'"
        class="cursor-pointer q-ml-none q-mr-sm q-pa-sm"
        @click.capture.stop="chip.fn ? chip.fn() : ''"
      >
        <ActionTooltip
          v-if="chip.tooltip"
          :str="chip.tooltip"
        />
      </QChip>
    </QCardSection>

    <QSeparator
      v-if="$_.last(applicationsNotes)"
      class="q-my-xs q-px-md"
    />
    <QCardSection
      v-if="$_.last(applicationsNotes)"
      class="q-pt-none"
    >
      <div class="flex items-center">
        <QIcon size="sm" color="grey-5" name="uil:notes" />
        <AppContent
          path="time.datetime"
          class="text-caption text-grey-5 q-ml-xs"
          :options="{ fmtd: $_.last(applicationsNotes).date }"
        />
        <AppContent
          v-if="$_.last(applicationsNotes).author"
          path="pages.blog.by"
          class="text-caption text-grey-5 q-ml-sm"
          :options="{ author: $_.last(applicationsNotes).author }"
        />
      </div>
      <div class="text-caption text-grey-5">
        {{ $_.last(applicationsNotes).note }}
      </div>
    </QCardSection>

    <QCardSection
      v-if="hcMenuActions.length"
      class="q-pt-none"
    >
      <HCMenu
        :actions="hcMenuActions"
        :element="applicant"
        force-expand
      />
    </QCardSection>
    <slot name="bottomActions" />
  </QCard>
</template>

<script>
import { fromNow } from 'hc-core/composables/time.js'
import { escapeUrl, pickFirstKey, startCaseUser } from 'hc-core/composables/misc.js'
import { dlKeyFromS3 } from 'hc-core/composables/aws.js'
import HCMenu from 'hc-core/components/common/hc-menu'
import ApplicantListMixins from 'hc-core/mixins/applicant-list.js'

export default {
  components: { HCMenu },
  mixins: [ApplicantListMixins],
  props: {
    // Would be better if autonomous based on this only
    applicantId: {
      type: String,
      // required: true,
      default: null
    },
    // If applicant already full fed, pass this
    alreadyFed: {
      type: Object,
      default: null
    },
    // If in context of CVTheque, base restricted status on presence in CVThqueList
    lockCvtheque: {
      type: Boolean,
      default: false
    },
    // If in context of CVTheque, show loading state when unlock is in progress
    propLoading: {
      type: Boolean,
      default: false
    },
  },
  emits: ['chipClick'],
  data () {
    return {
      loading: true,
      // Stelace user, with added properties : restricted<Boolean>, applications<Array>, profileAsset<Asset of Profile type>
      applicant: null
    }
  },
  computed: {
    isVivier () { return this.$route.name === 'applicants' },
    cptLoading () { return this.propLoading || this.loading },

    // Snippet : check restricted status for one applicant
    // Restricted is not paid client OR (inCVTheque AND not Premium)
    isRestricted () { return this.$_.get(this.applicant, 'applications', []).map(app => app.status).includes('restricted') || (this.lockCvtheque && (!this.isPremium() && !this.isCVThequeUnlocked(this.applicant.id))) },

    cvThequeUnlockTimestamp () {
      if (this.isCVThequeUnlocked(this.applicant.id)) {
        const cvthequeStructItem = this.$_.get(this.cvthequeList, 'data.listStructure[0].applicantsIds', []).find(item => (this.$_.isString(item) ? item === this.applicant.id : item.id === this.applicant.id))
        return {
          unlocked: true,
          since: this.$_.get(cvthequeStructItem, 'timestamp', false) ? `Date de déverrouillage : ${this.$t({ id: 'time.date' }, { fmtd: cvthequeStructItem.timestamp })}` : 'Candidat déverrouillé'
        }
      } else return false
    },

    jobrefLabel () {
      let val = this.$_.get(this.applicant, 'profileAsset.customAttributes.jobReferential', null)
      if (val) val = this.$_.get(this.getCustomAttributeValues('jobReferential').find(jr => jr.value === val), 'label', null)
      else val = this.$_.get(this.applicant, 'metadata._resume.preferredJob', null)
      return val
    },

    // Snippet : grab and order all applications notes
    applicationsNotes () {
      return this.$_.orderBy(
        this.$_.flattenDeep(
          this.$_.get(this.applicant, 'applications', []).map(a => this.$_.get(a, 'metadata._company.notes', []))
        ), ['date'], ['asc'])
    },

    chips () {
      const chips = []

      // DownloadResume
      const cvPath = pickFirstKey(this.applicant, ['profileAsset.metadata._files.resume', 'metadata._files.resume'], false)
      if (cvPath) {
        chips.push({
          icon: 'uil:import',
          label: 'CV',
          tooltip: 'Télécharger le CV',
          fn: async (d) => {
            if (this.applicant.restricted || this.isRestricted) {
              this.emitEvent({
                action: 'openRestricted',
                chip: 'openRestricted',
              })
            } else {
              await dlKeyFromS3({
                type: 'pdf',
                key: cvPath,
                dlLabel: `CV_${this.$_.get(this.applicant, 'firstname', '')}_${this.$_.get(this.applicant, 'lastname', '')}`
              })
            }
          }
        })
      }

      // Last Activity
      const lastActivityValue = this.$_.get(this.applicant, 'profileAsset.customAttributes.lastActivity', false)
      const lastActivity = lastActivityValue ? this.fromNow(lastActivityValue, true) : false
      if (lastActivity) {
        chips.push({
          icon: 'uil:clock',
          label: lastActivity,
          tooltip: 'Dernière activité',
          fn: (i) => {
            this.emitEvent({
              action: this.isRestricted ? 'openRestricted' : 'files',
              chip: this.isRestricted ? 'openRestricted' : 'files',
            })
          },
        })
      }

      // CV Update
      const cvUpdateValue = pickFirstKey(this.applicant, ['profileAsset.customAttributes.resumeUpdate', 'profileAsset.metadata._files.resumeUpdate', 'metadata._files.resumeUpdate'], false)
      const cvUpdate = cvUpdateValue ? this.fromNow(cvUpdateValue, true) : false
      if (cvUpdate) {
        chips.push({
          icon: 'uil:file-upload-alt',
          label: cvUpdate,
          tooltip: 'Mise à jour du CV',
          fn: (i) => {
            this.emitEvent({
              action: 'files',
              chip: 'files',
            })
          },
        })
      }

      // Location
      const location = pickFirstKey(this.applicant, ['profileAsset.locations[0]', 'metadata._private.locations[0]'], false)
      if (location) {
        chips.push({
          icon: 'uil:map-pin-alt',
          label: location.city,
          tooltip: 'Adresse du candidat (ouvrir Maps)',
          fn: async (d) => {
            this.emitEvent({
              action: 'openMapsOnLocation',
              location
            })
          }
        })
      }

      // Experience
      const experience = pickFirstKey(this.applicant, ['profileAsset.customAttributes.experienceNb', 'metadata._resume.experience'], false)
      if (experience) {
        chips.push({
          icon: 'uil:flask',
          label: `${experience} ans`,
          tooltip: 'Années d\'expérience',
          fn: async (d) => {
            this.emitEvent({
              action: this.isRestricted ? 'openRestricted' : 'files',
              chip: this.isRestricted ? 'openRestricted' : 'files',
            })
          }
        })
      }

      // Last - current job
      const lastJob = pickFirstKey(this.applicant, ['profileAsset.metadata._resume.experiences[0]', 'metadata._resume.experiences[0]'], false)
      if (lastJob) {
        chips.push({
          icon: 'uil:briefcase',
          label: this.$_.truncate(lastJob.title),
          tooltip: 'Dernier poste (ou poste en cours)',
          fn: async (d) => {
            this.emitEvent({
              action: this.isRestricted ? 'openRestricted' : 'files',
              chip: this.isRestricted ? 'openRestricted' : 'files',
            })
          }
        })
      }

      // Education
      const education = pickFirstKey(this.applicant, ['profileAsset.metadata._resume.education', 'metadata._resume.education'], [])
      if (education.length) {
        chips.push({
          icon: 'uil:graduation-cap',
          label: `${this.$_.truncate(this.$_.get(education, '[0].title', 'Diplômes'))}${education.length > 1 ? ` (+${education.length - 1})` : ''}`,
          tooltip: education.map(e => e.title).join(', '),
          fn: async (d) => {
            this.emitEvent({
              action: this.isRestricted ? 'openRestricted' : 'files',
              chip: this.isRestricted ? 'openRestricted' : 'files',
            })
          }
        })
      }

      // LinkedIn
      const linkedin = pickFirstKey(this.applicant, ['profileAsset.metadata._resume.links.linkedin', 'metadata._private.links.linkedin'], false)
      if (linkedin) {
        chips.push({
          icon: 'uil:linkedin',
          label: 'LinkedIn',
          tooltip: 'Profil LinkedIn du candidat',
          fn: async (d) => {
            if (this.applicant.restricted || this.isRestricted) {
              this.emitEvent({
                action: 'openRestricted',
                chip: 'openRestricted',
              })
            } else window.open(escapeUrl(linkedin), '_blank')
          }
        })
      }

      // NbApplications
      const itemApplications = this.$_.get(this.applicant, 'applications', [])
      if (itemApplications.length) {
        chips.push({
          icon: 'uil:paperclip',
          label: itemApplications.length,
          tooltip: 'Candidatures',
          fn: async (d) => {
            this.emitEvent({
              action: this.isRestricted ? 'openRestricted' : 'applications',
              chip: this.isRestricted ? 'openRestricted' : 'applications',
            })
          }
        })
      }

      // FromNowTime
      const fromNowTime = this.applicationTime(this.$_.head(this.$_.orderBy(itemApplications, (a) => { return this.applicationTime(a) }, ['desc'])))
      if (fromNowTime) {
        chips.push({
          icon: 'uil:clock',
          label: this.fromNow(fromNowTime, true),
          tooltip: 'Denière candidature',
          fn: (i) => {
            this.emitEvent({
              action: 'applications',
              chip: 'applications',
            })
          },
        })
      }

      // NotesNb
      const notesNb = this.applicationsNotes.length
      if (notesNb) {
        chips.push({
          icon: 'uil:comment-alt-message',
          label: notesNb,
          tooltip: `${notesNb} notes`,
          fn: (i) => {
            this.emitEvent({
              action: 'applications',
              chip: 'applications',
            })
          },
        })
      }

      // Favorite
      if (this.isSubscribed()) {
        const isItemFav = this.isFavorite(this.$_.get(this.applicant, 'id', null))
        chips.push({
          icon: 'uil:star',
          label: 'Favori',
          color: isItemFav ? 'gold-4' : 'grey-2',
          tooltip: this.$t({ id: isItemFav ? 'applicantList.fav_remove' : 'applicantList.fav_add' }),
          fn: async (d) => { await this.toggleApplicantOfList(this.$_.get(this.applicant, 'id', null)) }
        })
      }
      return chips
    },

    // Actions for HCMenu
    hcMenuActions () {
      if (this.isRestricted || !this.isVivier) return []
      const cvPath = pickFirstKey(this.applicant, ['profileAsset.metadata._files.resume', 'metadata._files.resume'], false)
      const notesNb = this.applicationsNotes.length
      const actions = [
        {
          icon: 'uil:notes',
          color: notesNb > 0 ? 'positive' : 'grey',
          label: this.$t({ id: 'component.cards.applicant_card.nb_notes' }, { nb: notesNb }),
          fn: (i) => {
            this.emitEvent({
              action: 'applications',
              chip: 'applications',
            })
          },
        },
        {
          icon: 'uil:envelope-alt',
          color: 'grey',
          label: 'component.cards.applicant_card.show_messages',
          fn: (i) => {
            this.emitEvent({
              action: 'messages',
              chip: 'messages',
            })
          },
        },
        {
          icon: 'uil:import',
          color: cvPath ? 'positive' : 'grey',
          label: 'Télécharger le CV',
          if: !!cvPath,
          fn: async (i) => {
            await dlKeyFromS3({
              type: 'pdf',
              key: cvPath,
              dlLabel: `CV_${this.$_.get(this.applicant, 'firstname', '')}_${this.$_.get(this.applicant, 'lastname', '')}`
            })
          }
        }
      ]
      if (actions.filter(a => a.if).length) return actions
      else return []
    },
  },
  watch: {
    alreadyFed: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val) {
          this.applicant = startCaseUser(val)
          this.loading = false
        }
      }
    },
    // applicantId: {
    //   deep: true,
    //   immediate: true,
    //   handler: function (val) {
    //     if (val) this.fetchApplicant()
    //   }
    // },
  },
  methods: {
    fromNow,

    // async fetchApplicant () {
    //   try {
    //     this.loading = true
    //     // fetch applicant with all related data
    //     const applicant = await this.$store.dispatch('user/read', { id: this.applicantId })
    //     const applications = await this.$store.dispatch('transaction/list', {
    //       takerId: applicant.id,
    //       ownerId: this.$uElements('targetId')
    //     })
    //     this.applicant = this.$_.set(applicant, 'applications', this.$_.get(applications, 'results', []))
    //     // resutls
    //   } catch (e) {
    //     this.useLogger(e)
    //   } finally {
    //     this.loading = false
    //   }
    // },

    // Snippet : lastTime creation for application
    applicationTime (a) { return this.pickFirstKey(a, ['platformData.unrestrictedDate', 'platformData._v1.created', 'createdDate']) },

    // ##### Below are computedItems snippets #####
    emitEvent (evt) {
      this.$emit('chipClick', {
        // Default, doubled since both used by kanbanv2 and applicantsDisplay, but not same handling
        item: this.$_.set(this.applicant, 'isRestricted', this.isRestricted),
        value: this.$_.set(this.applicant, 'isRestricted', this.isRestricted),
        chip: 'files',
        action: 'files',
        // Then override
        ...evt
      })
    },
  }
}
</script>
